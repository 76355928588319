
import { onMounted, defineComponent, ref, onUnmounted, computed } from "vue"

import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Footer from "../components/footer.vue"
import axios from "axios"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    Button,
    Icon,
    Footer,
  },
  setup() {
    const url = "https://api.otonomi.no"

    onMounted(() => {
      getProducts()
    })

    const products = ref({})
    let selectedProductSize = ref("S")

    const selectedProduct = computed(() => {
      for (const [key, value] of Object.entries(products.value)) {
        if (key == selectedProductSize.value) {
          return value
        }
      }
    })

    const getProducts = async () => {
      axios({
        baseURL: url,
        url: "/order/products/",
        data: {},
        method: "GET",
      })
        .then((resp) => {
          products.value = resp.data
          console.log(products.value)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return {
      products,
      selectedProductSize,
      selectedProduct,
    }
  },
})
