<script lang="ts">
import { onMounted, defineComponent, ref, onUnmounted, computed } from "vue"

import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"
import Footer from "../components/footer.vue"
import axios from "axios"
import { useStore } from "vuex"

export default defineComponent({
  components: {
    Button,
    Icon,
    Footer,
  },
  setup() {
    const url = "https://api.otonomi.no"

    onMounted(() => {
      getProducts()
    })

    const products = ref({})
    let selectedProductSize = ref("S")

    const selectedProduct = computed(() => {
      for (const [key, value] of Object.entries(products.value)) {
        if (key == selectedProductSize.value) {
          return value
        }
      }
    })

    const getProducts = async () => {
      axios({
        baseURL: url,
        url: "/order/products/",
        data: {},
        method: "GET",
      })
        .then((resp) => {
          products.value = resp.data
          console.log(products.value)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    return {
      products,
      selectedProductSize,
      selectedProduct,
    }
  },
})
</script>

<template>
  <main>
    <section class="page">
      <section class="pricing text-center">
        <div class="content align-center" v-if="products">
          <h1>Flexible pricing</h1>
          <p class="light-text">{{ $t(`pricing.pricing`) }}</p>

          <div class="priceSelections flex justify-center">
            <div v-for="(product, name, index) in products" :key="name" class="priceItem">
              <input :id="index" type="radio" :value="name" v-model="selectedProductSize"
                :checked="index === 0 ? true : false" />
              <label :for="index">
                {{ name }}
              </label>
            </div>
          </div>

          <!-- <div v-if="products["L"]["PACKAGE"]">{{ products["L"].PACKAGE }}</div> -->

          <div v-if="selectedProduct">
            <p class="light-text">
              {{ $t(`sizes.${selectedProductSize}`) }}
            </p>
            <div v-for="(pContent, pItem) in selectedProduct" :key="pContent">
              <ul class="pricing-container" v-if="pItem == 'PACKAGE'">
                <li class="PricingPlanBox white-box" v-for="(content, item) in pContent" :key="content">
                  <div class="top-half">
                    <div class="size">{{ item }}</div>
                    <h2 class="price">{{ content.price }} kr</h2>
                    <p class="description">
                      {{ $t(`pricing.${item}Desc`) }}
                    </p>
                  </div>
                  <div class="bottom-half">
                    <ul class="features">
                      <li class="item">
                        <span class="number">
                          <span v-if="content.iterations">{{
                          content.iterations
                          }}</span><span v-else>0</span></span>
                        <span class="feature">
                          {{ $t(`pricing.${item}Sim`) }}</span>
                      </li>
                      <li class="item">
                        <span class="number">{{
                        content.reports ? content.reports : 0
                        }}</span>
                        <span class="feature">{{
                        $t(`pricing.${item}Rep`)
                        }}</span>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </section>
  </main>
  <Footer></Footer>
</template>

<style lang="scss" scoped>
@import "../css/home.scss";

.page {
  font-size: 1rem;
  margin: 200px auto;
  width: 80vw;
  max-width: 1000px;
}

.white-box {
  background: white;
  padding: 30px;
  text-align: left;
  border-radius: 24px;
  box-shadow: var(--shadow-light);
}

.light-text {
  color: var(--color-text-light);
}

.priceSelections {
  margin-bottom: 2rem;

  .priceItem {
    padding: 5px;
    border-radius: 12px;
    font-weight: 700;
    margin-right: 1rem;

    &:has(input[type="radio"]:checked) {
      background: var(--color-blue);
    }

    label {
      padding: 0px 12px 0 5px;
      font-size: 1.3rem;
    }
  }
}

.pricing-container {
  display: flex;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;

  @media (max-width: 800px) {
    flex-direction: column;
  }

  .PricingPlanBox {
    flex-grow: 1;
    flex-basis: 0;
    margin: 0 10px;
    max-width: 225px;
    margin: 0 auto;

    .top-half {
      border-bottom: 1px solid var(--color-light-blue-grey);
      padding-bottom: 10px;

      .title {
        font-size: 1.5rem;
        margin-top: 0;
        margin-bottom: 0;
        // color: var(--color-dark-green);
      }

      .size {
        font-size: 1rem;
        font-weight: 600;
        margin-top: 0;
        margin-bottom: 1.5rem;
        color: var(--color-dark-green);
      }

      .price {
        font-size: clamp(25px, 2.5vw, 3rem);
        letter-spacing: -0.2rem;
        font-weight: 900;
      }

      .description {
        font-size: 1rem;
        color: var(--color-text-light);
      }
    }

    .bottom-half {
      padding-top: 35px;

      .features {
        margin: 0;
        padding: 0 0 0 1.3rem;
      }

      .item {
        display: flex;
        margin-bottom: 10px;
      }

      .feature {
        font-size: 0.8rem;
        font-weight: 500;
        color: var(--color-text-light);
        margin: auto 10px;
      }

      .number {
        font-size: 1.1rem;
        font-weight: 700;
        position: relative;

        &::before {
          content: ">";
          position: absolute;
          left: -1.3rem;
          color: var(--color-dark-green);
        }
      }
    }
  }

  .highlight {
    background: var(--color-bg-dark);
    color: white;
    border: 5px solid white;
    box-sizing: border-box;
    box-shadow: var(--shadow-dark);
  }
}
</style>
